import Grid from "@mui/material/Grid"
import React, { useState } from "react"
import axios from "axios"
import TextField from "@mui/material/TextField"

import { useAppDispatch, useAppSelector } from "src/store/hooks"
import Loading from "src/components/ContentComponents/Loading/Loading"
import {
  setSetingsTerminBefore,
  setSetingsMaxTerminPerSlot,
} from "src/store/shop/shopSlice"

import { SubmitButtonSt, TitleSt, WrapHourSt } from "./Settings.styles"
import HourSelect from "./HourSelect"
import DateSelect from "./DateSelect"
import DisabelDate from "./DisabelDate"

const week = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri ", "Sat", "None"]

const SettingsDashBoard = () => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useAppDispatch()
  const { shopInfo } = useAppSelector(state => state?.shop)
  const { shopId } = shopInfo

  const {
    weekdays = [],
    time = "12:30",
    closedRegularDay = "none",
    closedSpecificDay,
    slotTime = "22:00",
    terminBefore = 2,
    maxTerminPerSlot = 2,
  } = shopInfo?.settings || {}

  const handleSubmitSettings = async () => {
    setIsLoading(true)
    console.log({ closedSpecificDay })
    const res = await axios.post(
      "/.netlify/functions/admin-setting-booking",
      JSON.stringify({
        shopId,
        weekdays,
        closedRegularDay,
        closedSpecificDay,
        time,
        slotTime,
        maxTerminPerSlot: maxTerminPerSlot || 2,
        _id: shopInfo?._id,
      })
    )
    setIsLoading(false)
    if (res.data === "EMAIL_SENT") {
      alert("Setting successfully")
    } else {
      alert("Something gone wrong.. try again")
    }
  }

  return (
    <div>
      {isLoading && <Loading />}
      <TitleSt>Setting Time/Date avaiable</TitleSt>
      <Grid container>
        <Grid item xs={12}>
          <WrapHourSt>
            <p>
              <strong>(Apply to daily***)</strong> MAX bookings / per SLOT:
            </p>
            <TextField
              id="standard-termin"
              label="Termins"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              value={maxTerminPerSlot || 8}
              onChange={event => {
                dispatch(setSetingsMaxTerminPerSlot(event.target.value))
              }}
            />
          </WrapHourSt>
        </Grid>
        <Grid item xs={12}>
          <WrapHourSt>
            <p>
              <strong>(Apply to daily***)</strong> Bookings avaiable before:
            </p>
            <TextField
              id="standard-hours"
              label="Hours"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              value={terminBefore || 0}
              onChange={event => {
                dispatch(setSetingsTerminBefore(event.target.value))
              }}
            />
          </WrapHourSt>
        </Grid>
        <Grid item xs={12}>
          <WrapHourSt>
            <p>
              <strong>(Apply to daily***)</strong> Disable bookings after:
            </p>
            <HourSelect slotTime={slotTime} />
          </WrapHourSt>
          <WrapHourSt>
            <p>
              <strong>(Apply to weekly***)</strong> ClosedDay:
            </p>
            <DateSelect slotDate={week} closedRegularDay={closedRegularDay} />
          </WrapHourSt>
        </Grid>

        <Grid container justifyContent={"center"} marginTop={4}>
          <Grid
            alignItems="center"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            item
            xs={12}
            maxWidth={650}
          >
            <p>
              <strong>Close </strong>a Specific day:
            </p>
            <DisabelDate />
          </Grid>
        </Grid>
      </Grid>
      <SubmitButtonSt onClick={handleSubmitSettings}>
        Update Settings
      </SubmitButtonSt>
    </div>
  )
}

export default SettingsDashBoard
