// import dayjs from "dayjs"

import { dayjsModified } from "src/utils"

export const updateList = (arr: number[], i: number) => {
  const newArr = arr.filter(num => num !== i)
  return newArr
}

export const isClosedDay = (day: string | Date, closedDays: Date[] = []) => {
  if (!closedDays?.length) {
    return false
  }

  const foundDayClosed = closedDays.find(closedDay =>
    dayjsModified(day).isSame(dayjsModified(closedDay), "day")
  )

  return !!foundDayClosed || dayjsModified(day).isBefore(dayjsModified(), "day")
}
