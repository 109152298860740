import DatePicker from "@mui/lab/DatePicker"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import { Grid, TextField } from "@mui/material"
import React, { useState } from "react"
import Chip from "@mui/material/Chip"

import { useAppDispatch, useAppSelector } from "src/store/hooks"
import {
  setAddClosedSpecificDay,
  setRemoveClosedSpecificDay,
} from "src/store/shop/shopSlice"
import { dayjsModified } from "src/utils"

import { isClosedDay } from "./utils"
import { StackSt } from "./Settings.styles"

const DisabelDate = () => {
  const [selectedDay, setSelectedDay] = useState(new Date())
  const dispatch = useAppDispatch()

  const { closedSpecificDay } = useAppSelector(
    store => store.shop.shopInfo.settings
  )
  const handleDelete = (dayDelete: Date) => {
    dispatch(setRemoveClosedSpecificDay(dayDelete))
  }
  return (
    <div>
      <Grid container justifyContent="center" marginY={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            shouldDisableDate={date => isClosedDay(date, closedSpecificDay)}
            label="Datum"
            inputFormat="MMM/dd/yyyy"
            value={selectedDay}
            onChange={newValue => {
              if (newValue) {
                setSelectedDay(newValue)
                dispatch(setAddClosedSpecificDay(newValue))
              }
            }}
            renderInput={params => <TextField variant="standard" {...params} />}
          />
        </LocalizationProvider>
      </Grid>
      <StackSt direction="row">
        {closedSpecificDay?.length > 0 &&
          closedSpecificDay?.map((day, i) => (
            <Chip
              key={`${day}${i}`}
              label={dayjsModified(day).format("DD/MM")}
              variant="outlined"
              onDelete={() => handleDelete(day)}
            />
          ))}
      </StackSt>
    </div>
  )
}

export default DisabelDate
